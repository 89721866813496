import { AreaStyle } from "@iventis/domain-model/model/areaStyle";
import { IconStyle } from "@iventis/domain-model/model/iconStyle";
import { LineStyle } from "@iventis/domain-model/model/lineStyle";
import { Model } from "@iventis/domain-model/model/model";
import { ModelStyle } from "@iventis/domain-model/model/modelStyle";
import { PointStyle } from "@iventis/domain-model/model/pointStyle";
import { ModeOfTransport } from "@iventis/domain-model/model/modeOfTransport";
import { RouteWaypoint as RouteWaypointDomain } from "@iventis/domain-model/model/routeWaypoint";
import GeoJSON from "geojson";
import { EventStream } from "@iventis/utilities";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { FilterFormat, Listener as IListener } from "@iventis/types";
import { DataFieldListItem } from "@iventis/domain-model/model/dataFieldListItem";
import { OptionalExceptFor } from "@iventis/types/useful.types";
import { TileCacheBuster } from "../bridge/mapbox/engine-mapbox-types-and-constants";
import { EventMessageMap } from "./map-stream.types";
import { MapObjectProperties, MapStore, UnionOfStyles } from "./store-schema";

export type EngineInterpreterOptions = {
    container: HTMLElement;
    store: MapStore;
    eventStream: EventStream<EventMessageMap>;
    preview: boolean;
    modifierKeys: KeyboardEvent["key"][];
    assetOptions: AssetOptions;
    bustTileCache?: TileCacheBuster;
    devTools?: boolean;
    routeApiFunctions: RouteApiFunctions;
    getAttributeListItems: (attributeId: string, filter?: FilterFormat[]) => Promise<DataFieldListItem[]>;
    user: { id: string; isMobileUser: boolean };
    isExternalUser: boolean;
};

export type RouteWaypoint = OptionalExceptFor<RouteWaypointDomain, "coordinates" | "name">;

export type RouteFinderResponse = { route: GeoJSON.LineString; waypoints: RouteWaypoint[]; duration: number };

export type RouteApiFunctions = {
    /** Given two or more waypoints, returns a line string connecting the them */
    routeFinder: (waypoints: OptionalExceptFor<RouteWaypoint, "coordinates">[], modeOfTransport: ModeOfTransport) => Promise<RouteFinderResponse>;
    /** Given an object id, get the waypoints along their route */
    getWaypoints: (objectId: string) => Promise<RouteWaypoint[]>;
    /** Maximum number of waypoints allowed for a given route */
    maxWaypoints: number;
};

export type AnySupportedGeometry = GeoJSON.LineString | GeoJSON.Polygon | GeoJSON.Point;

export type FixedShapeSupportedGeometry = GeoJSON.LineString | GeoJSON.Polygon;

export interface MoveEvent {
    objects: GeoJSON.Feature[];
    lng: number;
    lat: number;
}

export interface ClickEvent {
    objects: {
        layerId: string;
        objectId: string;
        properties: GeoJSON.GeoJsonProperties;
        geometry: GeoJSON.Geometry;
    }[];
    lng: number;
    lat: number;
}

export type QueryResponse = {
    layerName: string;
    layerId: string;
    objectId: string;
    geometry: GeoJSON.Geometry;
}[];

export type TypedFeature = GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>;

export type CompositionMapObject = {
    geojson: TypedFeature;
    objectId: string;
    layerId: string;
    level?: number;
    waypoints?: RouteWaypoint[];
};

export enum MapCursor {
    LOADING = "LOADING",
    COMPOSITION = "COMPOSITION",
    READ = "READ",
    POINTING = "POINTING",
    MOVE = "MOVE",
    NS_RESIZE = "NS_RESIZE",
    NESW_RESIZE = "NESW_RESIZE",
    EW_RESIZE = "EW_RESIZE",
    NWSE_RESIZE = "NWSE_RESIZE",
    DEFAULT = "DEFAULT",
    COMMENT = "COMMENT",
}

export type Listener = IListener;

export type ProjectionFunction = (coord: GeoJSON.Position) => [number, number];

export interface StylePropertyToValueMap<Style extends UnionOfStyles = UnionOfStyles> {
    styleProperty: keyof Style;
    value: Style[keyof Style];
}

export type StylePropertyValue = LineStyle[keyof LineStyle] | AreaStyle[keyof AreaStyle] | PointStyle[keyof PointStyle] | IconStyle[keyof IconStyle] | ModelStyle[keyof ModelStyle];

// Return specific to the getCurrentComposition function. Returns either a single or an array of composition map objects depending on the passed parameter
export type CompositionReturn<T extends boolean> = T extends true ? CompositionMapObject : CompositionMapObject[];

export type AssetOptions = {
    assetUrlGetter: (id: string, type?: AssetType) => Promise<string>;
    multipleAssetUrlGetter: (ids: string[]) => Promise<{ id: string; url: string; metaData?: { sdf?: boolean; customImage?: boolean } | null }[]>;
    multipleModelsGetter: (ids: string[]) => Promise<Model[]>;
    isAssetSdf: (id: string) => Promise<boolean>;
    bustCacheIds: (ids: string[]) => void;
};

export type ReflectLocalObjectsOptions = {
    allowTextOnSelectedObjects: boolean;
};
